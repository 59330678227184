// Styles file for `quickvasurvey`. Styles written here will override the
// corresponding component This is a stylesheet example of `Harpquiz`.

import { css, keyframes } from 'styled-components';
import images from './images';

// Theming
const DEFAULT_COLORS = {
  questionTitle: '#000',
  secondaryColor: '#49556a',
  text: '#000',
  headline: '#000',
  link: '#337ab7',
  choiceInput: '#fff',
  choiceInputHover: '',
  submitButtonBackground: '#235789',
  submitButtonText: '#fff',
  headerBackground: '#ebebec',
  mainBackground: '',
  surveyBackground: '',
  footerBackground: '#eff3f6',
  progressBarBackground: '#f5f5f5',
  progressBarFill: '#337ab7',
  sliderFill: '#f5f5f5',
};

const DEFAULT_FONT = {
  questionTitle: '22px',
  text: '16px',
  headline: '24px',
  submitButton: '20px',
  tcpa: '11px',
  footerText: '12px',
};

const DEFAULT_SIZE = {
  headerHeight: '85px',
  headerMobileHeight: '',
  submitButtonWidth: '276px',
  inputFieldHeight: '59px',
  inputFieldWidth: '660px',
  inputFieldMobileWidth: '',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${DEFAULT_SIZE.headerHeight}; /* Don't forget to change the min-height calculation for SurveyBackground */
  width: 100vw;
  padding: 0;
  margin: auto;
  background: ${DEFAULT_COLORS.headerBackground};
  @media (max-width: 801px) {
    height: 60px;
  }
`;

const HeaderImage = css`
  height: auto;
  width: 90%;
  max-width: 350px;
  max-height: 100%;
  margin-bottom: 0;
  @media (max-width: 801px) {
    max-width: 240px;
  }
`;

const StyledBg = css`
  background-attachment: fixed;
  background-size: cover !important;
  @media (max-width: 801px) {
    background-position: 98% 50% !important;
  }
`;

// Only used when sites background color is needed specificy "background-color"
// because of the blurred-rates image.
const AppWrapper = css`
  background-color: ${DEFAULT_COLORS.mainBackground} !important;
`;

// Main Background
const SurveyBackground = css`
  min-height: calc(100vh - 75px);
  max-width: 960px;
  width: 100vw;
  margin: auto;
  background-color: ${DEFAULT_COLORS.surveyBackground};
  @media (max-width: 801px) {
    min-height: calc(100vh - 60px);
  }
`;

const SurveyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 960px;
  padding-top: 60px;
  @media (max-width: 801px) {
    padding: 0;
  }
  div {
    &#alternate-container {
      /* Used if you need to overwrite the width of the hidden container */
      /* width: 100%; */
    }
  }
`;

const QuestionNumber = css`
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  color: #49556a;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  margin-bottom: 0;
  text-align: center;
  @media (max-width: 801px) {
    color: #000;
  }
`;

const FormContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: ${DEFAULT_COLORS.secondaryColor};
  margin: 60px auto 40px;
  padding: 35px 70px 50px;
  border-radius: 9px;
  background-color: #ebebec;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 802px;
  border: solid 1px #ebebec;
  @media (max-width: 801px) {
    background: ${({ primary }) => (primary ? '#EEEEEE' : 'transparent')};
    border: none;
    box-shadow: none;
    padding: ${({ primary }) => (primary ? '30px 0 0 0' : '0')};
    margin-top: ${({ primary }) => (primary ? '0' : '30px')};
  }
`;

const GlobalSealImg = css`
  margin: 20px;
  max-width: 110px;
`;

// Containers Multiple choice
const ChoiceContainer = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 0 0 0 50px;
  margin: 0 0 15px;
  border-radius: 5px;
  transition: background-color 230ms ease;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  background-color: ${DEFAULT_COLORS.choiceInput};
  color: ${DEFAULT_COLORS.text};
  cursor: pointer;
  position: relative;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding: 8px 40px 0;
  }

  &:before {
    position: absolute;
    box-sizing: border-box;
    top: 22px;
    left: 18px;
    content: '';
    padding: 0 5px;
    height: 14px;
    border: 2px solid #fff;
    outline: 1px solid #777;
    background: #fff;
  }
  span {
    font-size: inherit;
    @media (max-width: 801px) {
      font-size: 14px;
    }
  }

  &:active {
    border: 1px solid #979797;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  @media (hover: hover) {
    &:hover {
      border: 1px solid #979797;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};

  > input:checked + label {
    background: url(${images.Checkmark}) 94% 50%/20px no-repeat #fff;
    &:before {
      background-color: #00bf5f;
    }
  }
  > input[id='excellent'] + label {
    background: url(${images.crExcellent}) 50% 50%/85% no-repeat;
  }
  > input[id='good'] + label {
    background: url(${images.crGood}) 50% 50%/85% no-repeat;
  }
  > input[id='average'] + label {
    background: url(${images.crAverage}) 50% 50%/85% no-repeat;
  }
  > input[id='fair'] + label {
    background: url(${images.crFair}) 50% 50%/85% no-repeat;
  }
  > input[id='poor'] + label {
    background: url(${images.crPoor}) 50% 50%/85% no-repeat;
  }
  > input[id='excellent']:checked + label {
    background: url(${images.crExcellent}) 50% 50%/85% no-repeat #fefefe;
  }
  > input[id='good']:checked + label {
    background: url(${images.crGood}) 50% 50%/85% no-repeat #fefefe;
  }
  > input[id='average']:checked + label {
    background: url(${images.crAverage}) 50% 50%/85% no-repeat #fefefe;
  }
  > input[id='fair']:checked + label {
    background: url(${images.crFair}) 50% 50%/85% no-repeat #fefefe;
  }
  > input[id='poor']:checked + label {
    background: url(${images.crPoor}) 50% 50%/85% no-repeat #fefefe;
  }
`;

// Input
const inputStyle = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  border-radius: 3px;
  font-size: 16px;
  background: #fff;
  &:last-of-type {
    margin-bottom: 30px;
    .questionbody-mortgage_rate & {
      margin-bottom: 0;
    }
  }
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #eee'} !important; /* overrides initial setting*/

  ::-webkit-input-placeholder {
    text-align: center;
  }
  text-align: center;
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 5px;
  border: ${props => props.border};
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;
  p {
    margin-bottom: 0;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  margin: 0 0 5px;
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 98% 50%/15px no-repeat #fff;
  font-family: sans-serif;
  text-align: left;
  padding-left: 20px;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-left: 20px;
  }
`;

// Zip input
const StyledZip = css`
  ${inputStyle};
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};

  input,
  select {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    height: 40px;
    :last-of-type {
      margin-bottom: 5px;
    }
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;

  input {
    height: ${DEFAULT_SIZE.inputFieldHeight};
  }
`;

// Survey styles
const QuestionTitle = css`
  color: ${DEFAULT_COLORS.questionTitle};
  font-weight: 600;
  font-size: ${DEFAULT_FONT.questionTitle};
  line-height: 1;
  margin: 15px 0 25px;
  text-align: center;

  @media (max-width: 801px) {
    font-size: 18px;
  }
`;

const InputTitle = css`
  margin-bottom: 0;
`;

const Subtitle = css`
  text-align: center;
  margin: 0;
  color: #000;
  &.subtitle-credit_rating,
  &.subtitle-estimated_debt,
  &.subtitle-first_name {
    margin: -25px auto 20px;
  }
  &.subtitle-address {
    display: none;
  }
`;

const TipContainer = css`
  text-align: center;
  background: #fff;
  padding: 0;
  color: #000;
  font-size: 16px;
  line-height: 1.2;
  width: 100%;
  max-width: 660px;
  margin-top: 10px;
  margin-bottom: 0;
  @media (max-width: 801px) {
    width: calc(100% - 40px);
  }
`;

const QuestionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 840px) {
    width: 90%;
  }
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  &.questionbody-email {
    p.emailLabel {
      width: 100%;
      text-align: left;
      color: #000;
      margin-bottom: 5px;
    }
  }
  &.questionbody-mortgage_rate:last-of-type {
    margin-bottom: 30px;
  }
  .input-credit_rating {
    width: 16%;
    margin: 0 1%;
  }

  &.question-credit_rating {
    .input-credit_rating {
      width: 45%;
      max-width: 138px;
      margin-bottom: 15px;
    }
    justify-content: space-between;
    flex-direction: row-reverse;
    @media (max-width: 801px) {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    input + label {
      box-sizing: border-box;
      width: 100%;
      min-height: 125px;
      padding: 0;
      margin: 0 1%;
      font-size: 0;
      background-color: #dedede;
      border: 2px solid #004d77;
      border-radius: 20px;
      &:before {
        display: none;
      }
      &:hover {
        background-color: #eee;
      }
    }
    input:checked + label {
      border-color: #00bf5f;
    }
  }
`;

// Slider
const StyledSlider = css`
  width: 100%;
`;

const StyledThumb = css`
  background: url(${images.SliderArrows}) 50% 50%/14px no-repeat #333;
  border: 1px solid #bdc3c7;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: scale(1.1);
`;

const DisplayNumber = css`
  font-size: ${({ primary }) => (primary ? '18px' : '14px')};
  font-weight: 500;
  padding: 10px;
  margin-bottom: 0;
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

const SliderContainer = css`
  width: inherit;
  height: 10px; /* Don't forget to change the height of "FillContainer" as well */
  border-radius: 8px;
  border: 1px solid #bdc3c7;
  background: #fff;
`;

const FillContainer = css`
  position: absolute;
  height: 10px;
  border-radius: 8px 0 0 8px;
  background: ${DEFAULT_COLORS.sliderFill};
  width: ${props => `${props.width}%`};
`;

// ProgressBar
const ProgressContainer = css`
  margin: 0 auto 10px auto;
  width: 50vw;
  max-width: 400px;
  height: 20px;
  background: ${DEFAULT_COLORS.progressBarBackground};
  border-radius: 5px;
  overflow: hidden;
`;

// Progressbar animation
const ProgressBarStripes = keyframes`
  from {
    background-position: 50px 0;
  } to {
    background-position: 0 0;
  }
`;

const StatusBar = css`
  animation: ${ProgressBarStripes} 2s linear infinite;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${DEFAULT_COLORS.progressBarFill};
  transition: width 0.25s ease;
`;

// Used for the footer progressbar.
const FooterNumberStatus = css`
  /* position: absolute;
  left: 50%;
  top: 15px;
  margin-left: -52px;
  color: #fff;
  font-weight: 300;

  @media (max-width: 800px) {
    display: none;
  } */
`;

// buttons
const SubmitButton = css`
  box-sizing: border-box;
  margin: 0 0 10px;
  width: 100%;
  height: 54px;
  border-radius: 27px;
  background-color: ${props =>
    props.color || DEFAULT_COLORS.submitButtonBackground};
  border: 0px;
  font-size: ${DEFAULT_FONT.submitButton};
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${DEFAULT_COLORS.submitButtonText};
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding: 8px 0 0;
  }

  &:hover {
    opacity: 0.9;
  }

  /* Final Submit button is different than the 'next' buttons */
  &#submit-button {
    width: 300px;
  }
`;

const BackButton = css`
  border: none;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${DEFAULT_SIZE.submitButtonWidth};
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background: ${DEFAULT_COLORS.footerBackground};
  max-width: 960px;
  width: 100%;
  margin: auto;
  @media (max-width: 801px) {
    padding: 0 15px;
  }

  p {
    margin-bottom: 0;
  }

  a {
    color: ${DEFAULT_COLORS.link};
  }
`;

const DisclosureContainer = css`
  text-align: center;
  max-width: 960px;
  margin: 20px auto;

  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 10px auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 10px;

  span {
    color: #b9b9b9;
    font-size: 10px;
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: #b9b9b9;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    text-decoration: underline;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 33px;
  width: 33px;
  background: #fff;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 12px;
  color: ${props => props.primary && '#C1794C'};
  text-align: ${props => props.primary && 'center'};
  text-transform: uppercase;
`;

const Text = css`
  color: #000;
  font-size: 14px;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: ${props => props.primary && '0'};
`;

const FooterText = css`
  margin-top: ${props => props.primary && '30px'};
  white-space: pre-line;
  text-align: center;
  font-size: ${DEFAULT_FONT.footerText};
  color: ${({ primary }) => primary && '#767676'};
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  margin: 0 auto;
  @media (max-width: 801px) {
    padding: 10px 0;
    background: #004e7b;
  }
`;

const HeadlineHeader = css`
  color: ${DEFAULT_COLORS.headline};
  font-size: ${DEFAULT_FONT.headline};
  font-weight: 500;
  margin-bottom: 10px;

  @media (max-width: 800px) {
    font-size: 17px;
    font-weight: 600;
    color: #fff;
  }
`;

const SubHeader = css`
  font-size: 20px;
  font-weight: 400;
  color: ${DEFAULT_COLORS.headline};
  margin-bottom: 0;
  @media (max-width: 800px) {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000;
  text-align: center;
  font-size: 14px;
  margin-top: -20px;
  .question-container-first_name & {
    margin-top: 0;
  }
`;

const FinalDisclosureContainer = css`
  box-sizing: border-box;
  bottom: 0;
  padding: 20px;
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  min-height: 20px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  width: calc(100% + 140px);
  margin: 5px -75px 0 -75px;

  @media (max-width: 801px) {
    width: 100%;
  }

  p,
  label {
    margin-bottom: 2px;
    font-size: ${DEFAULT_FONT.tcpa};
    color: #767676;
    line-height: 1.4;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: none;
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > input {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    margin-left: 5px;
  }

  > input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset #777 0 0 2px;
    margin: 4px;
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: solid grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    margin: 4px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin-left: 40px;
  margin-bottom: 0;
  font-size: 0.9em;
  text-align: left;
  font-style: italic;
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
  }
  button {
    color: #00e;
    text-decoration: underline;
    margin: 0;
  }
`;

const AddressSubtext = css`
  margin: 0;
  width: 100%;
  text-align: left;
`;

const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${DEFAULT_COLORS.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;

export default {
  HeaderContainer,
  HeaderImage,
  StyledBg,
  AppWrapper,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  StyledSlider,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderContainer,
  FillContainer,
  ProgressContainer,
  StatusBar,
  FooterNumberStatus,
  SubmitButton,
  BackButton,
  ButtonContainer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
};
