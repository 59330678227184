// Images file for 'quickvasurvey'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import crAverage from '../../../../src/images/creditMeters/cr-average.png';
import crFair from '../../../../src/images/creditMeters/cr-below-average.png';
import crExcellent from '../../../../src/images/creditMeters/cr-excellent.png';
import crGood from '../../../../src/images/creditMeters/cr-good.png';
import crPoor from '../../../../src/images/creditMeters/cr-poor.png';

export default {
  Favicon,
  Dropdown,
  crAverage,
  crFair,
  crExcellent,
  crGood,
  crPoor,
};
